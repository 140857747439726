'use client';

import { Component, createContext } from 'react';
import { ThemeProvider } from 'styled-components';

//* Helpers
import themes from '@/styles/theme';
import Variables from '@/styles/variables';
import Typography from '@/styles/typography';
import HelperClass from '@/styles/helperClass';

const UIContext = createContext(null);

class UIProvider extends Component {
	//! States
	state = {
		winWidth: 1920,
		winHeight: 1080,
		screenSizes: {},
		isBurgerMenuOpen: false,
	};

	//! Methods
	methods = {
		toggleBurgerMenu: this.toggleBurgerMenu.bind(this),
		closeBurgerMenu: this.closeBurgerMenu.bind(this),
	};

	//! Toggle Burger Menu
	toggleBurgerMenu() {
		this.setState({ isBurgerMenuOpen: !this.state.isBurgerMenuOpen });
	}

	//! Close Burger Menu
	closeBurgerMenu() {
		this.state.isBurgerMenuOpen && this.setState({ isBurgerMenuOpen: false });
	}

	//! Screen Resize
	screenResize = () => {
		this.setState({
			winWidth: window.innerWidth,
			winHeight: window.innerHeight,
		});
	};

	//! Component Did Mount
	componentDidMount() {
		this.setState({
			...this.state,
		});

		window.addEventListener('resize', this.screenResize);
		this.screenResize();
	}

	//! Component Will Unmount
	componentWillUnmount() {
		window.removeEventListener('resize', this.screenResize);
		document.removeEventListener('keydown', this.escKeydown, false);
	}

	render() {
		return (
			<UIContext.Provider value={{ ...this.state, ...this.methods }}>
				<ThemeProvider theme={themes}>
					<Variables />

					<HelperClass />

					<Typography />

					{this.props.children}
				</ThemeProvider>
			</UIContext.Provider>
		);
	}
}

export default UIProvider;
export const UIConsumer = UIContext.Consumer;

import { template } from '@/helpers/lodash';

//* HOC's
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';

const Translate = (props) => {
	let compiled = props.languages[props.selectedLang]?.params[props.val] && template(props.languages[props.selectedLang]?.params[props.val]);
	return (compiled && compiled(props.vars)) || props.val || null;
};

export default withLanguageContext(Translate, '*');

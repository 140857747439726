export default {
	routes: {
		home: {
			path: '/',
			name: 'Home',
			variant: 'white',
		},

		news: {
			path: '/news/',
			name: 'news',
		},

		contact: {
			path: '/submit-issue/',
			name: 'contact',
		},

		about: {
			path: '/about/',
			name: 'about',
		},

		team: {
			path: '/team/',
			name: 'team',
		},

		reports: {
			path: '/reports/',
			name: 'reports',
		},

		reforms: {
			path: '/reforms/',
			name: 'reforms',
		},

		usefulMaterials: {
			path: '/useful-materials/',
			name: 'usefulMaterials',
		},

		structure: {
			path: '/structure/',
			name: 'structure',
		},

		councilMeetings: {
			path: '/council-meetings/',
			name: 'councilMeetings',
		},

		sustainabilityCorner: {
			path: '/sustainability-corner/',
			name: 'sustainabilityCorner',
		},
	},
};

import { createGlobalStyle, css } from 'styled-components';

function columnGenerator(suffix) {
	let style = '';

	for (let i = 1; i <= 12; i++) {
		style += suffix ? `.col-${suffix}-${i}` : `.col-${i}`;
		style += `{ width: ${(100 / 12) * i}% }`;
	}

	return style;
}

const HelperClass = createGlobalStyle`${css`
	//! Animation
	.opacity {
		&-0 {
			opacity: 0;
		}
	}

	.translate-y {
		&-100 {
			transform: translateY(100%);
		}
		&-50 {
			transform: translateY(50%);
		}
	}

	.overflow-hidden {
		overflow: hidden;
	}

	//! Fonts
	.font-montserrat-arm {
		&-regular {
			font-family: var(--montserrat_arm);
			font-weight: 400;
		}

		&-medium {
			font-family: var(--montserrat_arm);
			font-weight: 500;
		}

		&-bold {
			font-family: var(--montserrat_arm_bold);
			font-weight: 600;
		}
	}

	//! Colors
	.background-color {
		color: var(--backgroundColor);

		&-bg {
			background-color: var(--backgroundColor);
		}
	}

	.light-blue-color {
		color: var(--lightBlue);

		&-bg {
			background-color: var(--lightBlue);
		}
	}

	.blue-400-color {
		color: var(--blue400);

		&-bg {
			background-color: var(--blue400);
		}
	}

	.white-color {
		color: var(--white);

		&-bg {
			background-color: var(--white);
		}
	}

	.blue-1000 {
		color: var(--blue1000);

		&-bg {
			background-color: var(--blue1000);
		}
	}

	.blue-900 {
		color: var(--blue900);

		&-bg {
			background-color: var(--blue900);
		}
	}

	//! For Text
	.first-latter {
		&::first-letter {
			text-transform: uppercase;
		}
	}

	.uppercase {
		text-transform: uppercase;
	}

	.lowercase {
		text-transform: lowercase;
	}

	.capitalize {
		text-transform: capitalize;
	}

	.underline {
		text-decoration: underline;
	}

	.text-center {
		text-align: center;
	}

	.text-end {
		text-align: end;
	}

	.white-pre-wrap {
		white-space: pre-wrap;
	}

	.cursor-pointer {
		cursor: pointer;
	}

	//! Flex
	.flex {
		display: flex;
	}

	.justify-end {
		justify-content: flex-end;
	}

	.align-center {
		align-items: center;
	}

	//! Grid
	.row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 calc(var(--colPadding) * -1);

		> * {
			width: 100%;
			padding: 0 var(--colPadding);
		}

		${columnGenerator()};

		//! 1920
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.desktopSizeXLMin}) {
			${columnGenerator('dxl')}
		}

		//! 1536
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.desktopSizeLMin}) {
			${columnGenerator('dl')}
		}

		//! 1366
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.desktopSizeMMin}) {
			${columnGenerator('dm')}
		}

		//! 1280
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.desktopSizeSMin}) {
			${columnGenerator('ds')}
		}

		//! 768
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.tabletSizeMin}) {
			${columnGenerator('t')}
		}

		//! Mobile
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.tabletSizeSMin}) {
			${columnGenerator('m')}
		}
	}
`}`;

export default HelperClass;

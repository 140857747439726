export default {
	colors: {
		//! Primary
		blue1000: '#003D60',
		blue900: '#00496A',
		lightBlue: '#1579BE',

		blue400: '#97B9CC',
		blue300: '#B1CDDE',
		blue200: '#D0E4F2',
		blue100: '#DDEBF6',

		black1000: '#121212',
		black900: '#222222',
		black800: '#333333',
		black700: '#444444',

		//! Surface Backeground
		backgroundColor: '#EFF1F2',
		white: '#FFFFFF',
		black: '#000000',

		errorColor: '#ED6A71',
		grayHover: '#F8F8F8',
		grayInput: '#B6B9BB',
		grayDisable: '#E8E8E8',

		cartColor: '#B1CDDE',
	},

	mediaQuery: {
		desktopSizeXL: '2560px',
		desktopSizeL: '1920px',
		desktopSizeM: '1536px',
		desktopSizeS: '1366px',
		tabletSize: '1280px',
		tabletSizeS: '768px',

		desktopSizeXLMin: '2559px',
		desktopSizeLMin: '1919px',
		desktopSizeMMin: '1535px',
		desktopSizeSMin: '1365px',
		tabletSizeMin: '1279px',
		tabletSizeSMin: '767px',
	},
};

import { createGlobalStyle, css } from 'styled-components';
import localFont from 'next/font/local';

//* Theme
import theme from './theme';

//! Space Generator
function spaceGenerator() {
	let str = '';

	for (let i = 1; i <= 250; i++) {
		str += `--sp${i}x: calc(var(--spSize) * ${i});`;
	}

	return str;
}

//! Color Generator
function colorGenerator() {
	const colorsArr = Object.keys(theme.colors);

	let colorVariable = '';

	for (let i = 0; i < colorsArr.length; i++) {
		colorVariable += `--${colorsArr[i]}: ${theme.colors[colorsArr[i]]};`;
	}

	return colorVariable;
}

//! Fonts
const montserrat_arm = localFont({
	src: [
		{
			path: '../../public/fonts/Montserratarm-Regular.ttf',
			weight: '400',
			display: 'swap',
			style: 'normal',
		},
		{
			path: '../../public/fonts/Montserratarm-Regular.woff',
			weight: '400',
			display: 'swap',
			style: 'normal',
		},
		{
			path: '../../public/fonts/Montserratarm-Regular.woff2',
			weight: '400',
			display: 'swap',
			style: 'normal',
		},

		{
			path: '../../public/fonts/Montserratarm-Medium.ttf',
			weight: '500',
			display: 'swap',
			style: 'normal',
		},
		{
			path: '../../public/fonts/Montserratarm-Medium.woff',
			weight: '500',
			display: 'swap',
			style: 'normal',
		},
		{
			path: '../../public/fonts/Montserratarm-Medium.woff2',
			weight: '500',
			display: 'swap',
			style: 'normal',
		},

		{
			path: '../../public/fonts/Montserratarm-Bold.ttf',
			weight: '600',
			display: 'swap',
			style: 'normal',
		},
		{
			path: '../../public/fonts/Montserratarm-Bold.woff',
			weight: '600',
			display: 'swap',
			style: 'normal',
		},
		{
			path: '../../public/fonts/Montserratarm-Bold.woff2',
			weight: '600',
			display: 'swap',
			style: 'normal',
		},
	],
});

const Variables = createGlobalStyle`${css`
	:root {
		//! Fonts
		--montserrat_arm: ${montserrat_arm.style.fontFamily};

		//! Spacings
		--spSize: 8px;

		--sp0-5x: calc(var(--spSize) * 0.5);
		--sp1-5x: calc(var(--spSize) * 1.5);
		--sp2-5x: calc(var(--spSize) * 2.5);
		--sp3-5x: calc(var(--spSize) * 3.5);
		${spaceGenerator()};

		//! Transition
		--trTime: 0.3s;

		//! Line Heights
		--lineHeightM: 1.2;
		--lineHeightL: 1.5;

		//! Colors
		${colorGenerator()};

		//! Grid Column Distance
		--colPadding: calc(var(--gutter) / 2);
	}

	/* //! 2560 */
	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeXL}) {
		:root {
			--h1: 72px;
			--h2: 64px;
			--h3: 56px;
			--h4: 48px;
			--h5: 40px;
			--h6: 32px;
			--p1: 28px;
			--p2: 24px;
			--p3: 20px;
			--p4: 18px;
			--p5: 16px;
			--pInput: 20px;

			//! Global Variables
			--contPaddingLR: var(--sp20x);
			--gutter: var(--sp4x);
			--sectionDistance: var(--sp30x);
			--headerFixedHeight: var(--sp18x);
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		:root {
			--h1: 56px;
			--h2: 46px;
			--h3: 40px;
			--h4: 32px;
			--h5: 28px;
			--h6: 24px;
			--p1: 20px;
			--p2: 18px;
			--p3: 16px;
			--p4: 16px;
			--p5: 14px;
			--pInput: 18px;

			//! Global Variables
			--contPaddingLR: calc(var(--sp17x) + var(--sp0-5x));
			--gutter: var(--sp2x);
			--sectionDistance: var(--sp20x);
			--headerFixedHeight: calc(var(--sp12x) + var(--sp0-5x) / 2);
		}
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		:root {
			--h1: 48px;
			--h2: 40px;
			--h3: 32px;
			--h4: 28px;
			--h5: 24px;
			--h6: 20px;
			--p1: 18px;
			--p2: 16px;
			--p3: 16px;
			--p4: 14px;
			--p5: 14px;
			--pInput: 16px;

			//! Global Variables
			--contPaddingLR: var(--sp11x);
			--gutter: var(--sp2x);
			--sectionDistance: var(--sp15x);
			--headerFixedHeight: calc(var(--sp10x) + var(--sp0-5x) / 2);
		}
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		:root {
			--h1: 40px;
			--h2: 32px;
			--h3: 28px;
			--h4: 24px;
			--h5: 20px;
			--h6: 18px;
			--p1: 16px;
			--p2: 16px;
			--p3: 14px;
			--p4: 14px;
			--p5: 12px;
			--pInput: 16px;

			//! Global Variables
			--contPaddingLR: var(--sp9x);
			--gutter: var(--sp2x);
			--sectionDistance: var(--sp13x);
			--headerFixedHeight: calc(var(--sp10x) + var(--sp0-5x) / 2);
		}
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		:root {
			--h1: 40px;
			--h2: 28px;
			--h3: 24px;
			--h4: 20px;
			--h5: 18px;
			--h6: 16px;
			--p1: 16px;
			--p2: 14px;
			--p3: 14px;
			--p4: 12px;
			--p5: 12px;
			--pInput: 16px;

			//! Line Heights
			--lineHeightL: 1.4;

			//! Global Variables
			--contPaddingLR: var(--sp8x);
			--gutter: var(--sp2x);
			--sectionDistance: var(--sp12x);
			--headerFixedHeight: calc(var(--sp11x) - var(--sp0-5x) / 2);
		}
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		:root {
			--h1: 32px;
			--h2: 28px;
			--h3: 24px;
			--h4: 20px;
			--h5: 18px;
			--h6: 16px;
			--p1: 14px;
			--p2: 14px;
			--p3: 12px;
			--p4: 12px;
			--p5: 12px;
			--pInput: 16px;

			//! Line Heights
			--lineHeightL: 1.4;

			//! Global Variables
			--contPaddingLR: var(--sp6x);
			--gutter: var(--sp2x);
			--sectionDistance: var(--sp11x);
			--headerFixedHeight: var(--sp10x);
		}
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		:root {
			--h1: 32px;
			--h2: 24px;
			--h3: 20px;
			--h4: 18px;
			--h5: 16px;
			--h6: 14px;
			--p1: 14px;
			--p2: 12px;
			--p3: 12px;
			--p4: 12px;
			--p5: 12px;
			--pInput: 16px;

			//! Line Heights
			--lineHeightL: 1.4;

			//! Global Variables
			--contPaddingLR: var(--sp2x);
			--gutter: var(--sp1x);
			--sectionDistance: var(--sp8x);
			--headerFixedHeight: calc(var(--sp10x) - var(--sp0-5x) / 2);
		}
	}
`}`;

export default Variables;
